import Vue from "vue";
import autoTable from "jspdf-autotable";
import moment from "moment";
import api from "../axios";
import jsPDF from "jspdf";

const showLoading = () => {
  Vue.prototype.$vs.loading();
};

const hideLoading = () => {
  Vue.prototype.$vs.loading.close();
};

const getDebitNote = async (ids) => {
  return api.get("api/v1/debit-note/dn-lines", { params: { ids } });
};

const getDebitNoteDetail = async (id) => {
  return api.get(`/api/v1/debit-note/return/detail`, {
    params: {
      id: id,
    },
  });
};

const getSupplierReturnDetails = async (params) => {
  return api.get("/api/v1/debit-note/supplier-return/detail", {
    params,
  });
};

const setPos = (pageWidth, pos, offset) => {
  if (pos >= pageWidth) {
    return pageWidth;
  } else {
    if (pos <= 0) {
      return offset;
    } else {
      return (pos += offset);
    }
  }
};

const priceFormat = (amount) => {
  const newAmount = amount
    .toString()
    .replace(/[^.\d]/g, "")
    .toString();
  console.log(amount, newAmount);
  amount = parseFloat(newAmount).toFixed(2);

  return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const formatDate = (val, long = null) => {
  const momentDate = moment.utc(val);
  return momentDate.format(long ? "DD.MM.YYYY" : "DD MMMM YYYY");
};

const generateDebitNoteReturnHeader = (
  pdf,
  pageWidth,
  pageHeight,
  padding,
  debitNote,
  taxInvoiceReference,
  company,
  supplier
) => {
  pdf.setLineWidth(0.07);
  pdf.rect(
    setPos(pageWidth, 0, padding),
    setPos(pageHeight, 0, padding),
    setPos(pageWidth, pageWidth, padding),
    1.8
  );

  let posY = setPos(pageHeight, 0.5, padding);
  const title = "NOTA RETUR";
  const titleLength = pdf.getTextDimensions(title).w;
  const innerPadding = 0.3;
  pdf.setFontSize(15);
  pdf.setFont("helvetica", "bold");
  pdf.text(
    title,
    setPos(pageWidth, pageWidth / 2 + titleLength / 4, padding),
    setPos(pageHeight, posY, 0),
    "center"
  );

  const spacing = 0.7;

  posY += 0.5;

  pdf.setFontSize(10);

  pdf.setFont("helvetica", "normal");
  const number = `Nomor`;
  pdf.text(
    number,
    setPos(pageWidth, pageWidth - pageWidth / 3, padding),
    setPos(pageHeight, posY, 0)
  );

  const numberValue = `: ${debitNote.Code}`;

  pdf.text(
    numberValue,
    setPos(pageWidth, pageWidth - pageWidth / 3 + 2, padding),
    setPos(pageHeight, posY, 0)
  );

  posY += 0.5;

  const taxNumber = "Atas Faktur Pajak Nomor";
  pdf.text(taxNumber, setPos(pageWidth, innerPadding, padding), posY);

  const taxNumberValue = `: ${
    Object.keys(debitNote).length > 0 && debitNote.TaxInvoiceReferenceRefText
      ? debitNote.TaxInvoiceReferenceRefText
      : taxInvoiceReference.TaxReturnNumber
  }`;

  pdf.text(
    taxNumberValue,
    setPos(pageWidth, innerPadding + 4.3, padding),
    posY
  );

  pdf.text(taxNumber, setPos(pageWidth, innerPadding, padding), posY);

  const date = `tgl`;
  pdf.text(
    date,
    setPos(pageWidth, pageWidth - pageWidth / 3, padding),
    setPos(pageHeight, posY, 0)
  );

  const dateValue = `: ${formatDate(
    debitNote.TaxInvoiceReferenceRefDate
      ? debitNote.TaxInvoiceReferenceRefDate
      : taxInvoiceReference.TaxReturnDate,
    true
  )}`;

  pdf.text(
    dateValue,
    setPos(pageWidth, pageWidth - pageWidth / 3 + 2, padding),
    setPos(pageHeight, posY, 0)
  );

  const startLine = posY + 0.3;
  posY += 0.9;

  pdf.text(
    "PEMBELI",
    setPos(pageWidth, innerPadding, padding),
    setPos(pageHeight, posY, 0)
  );

  posY += spacing;

  pdf.text(
    "Nama",
    setPos(pageWidth, innerPadding, padding),
    setPos(pageHeight, posY, 0)
  );

  pdf.text(
    `: ${company.Name}`,
    setPos(pageWidth, innerPadding + 2, padding),
    setPos(pageHeight, posY, 0)
  );

  posY += spacing;

  const addrHeader = "Alamat";
  const addrWidth = pdf.getTextDimensions(addrHeader).w;
  const addrHeight = pdf.getTextDimensions(addrHeader).h;
  const companyAddr = company.Address;
  const companyAddrLines = pdf.splitTextToSize(
    companyAddr,
    pageWidth - addrWidth - 1
  );

  pdf.text(
    addrHeader,
    setPos(pageWidth, innerPadding, padding),
    setPos(pageHeight, posY, 0)
  );

  pdf.text(
    ":",
    setPos(pageWidth, innerPadding + 2, padding),
    setPos(pageHeight, posY, 0)
  );

  pdf.text(
    companyAddrLines,
    setPos(pageWidth, innerPadding + 2.25, padding),
    setPos(pageHeight, posY, 0)
  );

  posY += addrHeight * companyAddrLines.length + 0.35;

  pdf.text(
    "NPWP",
    setPos(pageWidth, innerPadding, padding),
    setPos(pageHeight, posY, 0)
  );

  pdf.text(
    `: ${company.Npwp}`,
    setPos(pageWidth, innerPadding + 2, padding),
    setPos(pageHeight, posY, 0)
  );

  posY += spacing;

  pdf.text(
    "KEPADA PENJUAL",
    setPos(pageWidth, innerPadding, padding),
    setPos(pageHeight, posY, 0)
  );

  posY += spacing;

  pdf.text(
    "Nama",
    setPos(pageWidth, innerPadding, padding),
    setPos(pageHeight, posY, 0)
  );

  pdf.text(
    `: ${supplier.Name}`,
    setPos(pageWidth, innerPadding + 2, padding),
    setPos(pageHeight, posY, 0)
  );

  posY += spacing;

  const supplierAddr = `${supplier.Address}, ${supplier.SubDistrict}, ${supplier.District}, ${supplier.City}, ${supplier.Province}`;
  const supplierAddrHeight = pdf.getTextDimensions(supplierAddr).h;
  const supplierAddrLines = pdf.splitTextToSize(
    supplierAddr,
    pageWidth - addrWidth - 2
  );

  pdf.text(
    addrHeader,
    setPos(pageWidth, innerPadding, padding),
    setPos(pageHeight, posY, 0)
  );

  pdf.text(
    ":",
    setPos(pageWidth, innerPadding + 2, padding),
    setPos(pageHeight, posY, 0)
  );

  pdf.text(
    supplierAddrLines,
    setPos(pageWidth, innerPadding + 2.25, padding),
    setPos(pageHeight, posY, 0)
  );

  posY += supplierAddrLines.length * supplierAddrHeight + 0.35;

  pdf.text(
    "NPWP",
    setPos(pageWidth, innerPadding, padding),
    setPos(pageHeight, posY, 0)
  );

  pdf.text(
    `: ${supplier.Npwp}`,
    setPos(pageWidth, innerPadding + 2, padding),
    setPos(pageHeight, posY, 0)
  );

  posY += spacing;

  //Draw right line
  pdf.line(
    setPos(pageWidth, 0, padding),
    setPos(pageHeight, startLine, 0),
    setPos(pageWidth, 0, padding),
    setPos(pageHeight, posY, 0)
  );

  //Draw left line
  pdf.line(
    pageWidth + padding,
    setPos(pageHeight, startLine, 0),
    pageWidth + padding,
    setPos(pageHeight, posY, 0)
  );

  return posY;
};

const skuGrouping = (lines) => {
  const filteredItems = {};
  lines.forEach((line, index) => {
    const price = line.DNValue / line.DNQuantity;

    if (index === 0) {
      const obj = {
        quantity: line.DNQuantity,
        value: line.DNValue,
        totalprice: price,
        priceRate: price,
        unit: line.UnitName,
        name: line.ItemName,
        totalLine: 1,
      };
      filteredItems[line.ItemUnitID] = obj;
      return; //next iteration
    }

    if (
      line.ItemUnitID in filteredItems &&
      line.ItemName === filteredItems[line.ItemUnitID].name
    ) {
      filteredItems[line.ItemUnitID].quantity += line.DNQuantity;
      filteredItems[line.ItemUnitID].value += line.DNValue;
      filteredItems[line.ItemUnitID].totalprice += price;
      filteredItems[line.ItemUnitID].totalLine++;
      filteredItems[line.ItemUnitID].priceRate =
        filteredItems[line.ItemUnitID].totalprice /
        filteredItems[line.ItemUnitID].totalLine;
    } else {
      const obj = {
        quantity: line.DNQuantity,
        value: line.DNValue,
        totalprice: price,
        priceRate: price,
        unit: line.UnitName,
        name: line.ItemName,
        totalLine: 1,
      };
      filteredItems[line.ItemUnitID] = obj;
    }
  });

  const body = Object.keys(filteredItems).map((key, index) => {
    return {
      no: index + 1,
      item_name: filteredItems[key].name,
      quantity: `${filteredItems[key].quantity} ${filteredItems[key].unit}`,
      price: priceFormat(filteredItems[key].priceRate),
      value: priceFormat(filteredItems[key].value),
    };
  });

  return body;
};

const generateDebitNoteReturnLine = (pdf, posY, pageHeight, lines, padding) => {
  const header = [
    { header: "No. Urut", dataKey: "no" },
    { header: "Macam dan Jenis Barang Kena Pajak", dataKey: "item_name" },
    { header: "Kuantum", dataKey: "quantity" },
    { header: "Harga Satuan menurut Faktur Pajak", dataKey: "price" },
    { header: "Harga BKP yang dikembalikan (Rp)", dataKey: "value" },
  ];

  const body = skuGrouping(lines);

  autoTable(pdf, {
    startY: posY,
    columns: header,
    body: body,
    theme: "plain",
    styles: {
      lineWidth: 0,
      fontSize: 10,
      font: "helvetica",
      fontStyle: "normal",
      valign: "middle",
      lineColor: [0, 0, 0], //black
    },
    showHead: "everyPage",
    margin: {
      left: padding,
      right: padding,
      bottom: padding,
    },
    columnStyles: {
      no: { cellWidth: 1.5, cellPadding: 0 },
      item_name: { cellWidth: 9, cellPadding: 0 },
      quantity: { cellWidth: 3.5, cellPadding: 0 },
      price: { cellPadding: 0 },
      value: { cellWidth: 6, cellPadding: 0 },
    },
    didParseCell: (data) => {
      const columnIndex = data.column.index;
      const rowIndex = data.row.index;

      data.cell.styles.cellPadding = 0.14;

      if (data.section === "head") {
        if (columnIndex !== 1) {
          data.cell.styles.halign = "center";
          data.cell.styles.valign = "middle";
        } else {
          data.cell.styles.halign = "left";
          data.cell.styles.valign = "middle";
        }
      } else {
        if (columnIndex === 1) {
          data.cell.styles.halign = "left";
          data.cell.styles.valign = "middle";
        } else if (columnIndex === 2) {
          data.cell.styles.halign = "center";
          data.cell.styles.valign = "middle";
        } else {
          data.cell.styles.halign = "right";
          data.cell.styles.valign = "middle";
        }

        if (rowIndex < body.length - 1) {
          return;
        }

        // if (columnIndex === 0) {
        //   data.cell.styles.halign = "left";
        //   data.cell.colSpan = 4;
        // }
      }
    },
    didDrawCell(data) {
      const cellX = data.cell.x;
      const cellY = data.cell.y;
      if (data.row.section === "head") {
        pdf.setLineWidth(0.07);
        pdf.setDrawColor(0, 0, 0);
        //draw top line for all cells
        pdf.line(cellX, cellY, cellX + data.cell.width, cellY);

        //draw bottom line for all cells
        pdf.line(
          cellX,
          cellY + data.cell.height,
          cellX + data.cell.width,
          cellY + data.cell.height
        );

        //draw left line
        if (data.column.index === 0) {
          pdf.line(cellX, cellY, cellX, cellY + data.cell.height);
        }

        //draw right line
        pdf.line(
          cellX + data.cell.width,
          cellY,
          cellX + data.cell.width,
          cellY + data.cell.height
        );
      } else {
        //draw left line
        pdf.setLineWidth(0.07);
        pdf.setDrawColor(0, 0, 0);

        if (data.column.index === 0) {
          pdf.line(cellX, cellY, cellX, cellY + data.cell.height);
        }

        //draw right line
        pdf.line(
          cellX + data.cell.width,
          cellY,
          cellX + data.cell.width,
          cellY + data.cell.height
        );

        const posY = cellY + data.cell.height;
        const rowIndex = data.row.index;

        console.log("row index", rowIndex, rowIndex % 35);
        // if (columnIndex === 0) {
        //   itemCount++;
        // }

        // if (itemCount > 0 && itemCount % 36 === 0) {
        //   pdf.addPage();
        //   itemCount = 0;
        // }

        if (posY >= pageHeight + 0.5) {
          pdf.setLineWidth(0.07);
          pdf.setDrawColor(0, 0, 0);

          pdf.line(
            cellX,
            cellY + data.cell.height,
            cellX + data.cell.width,
            cellY + data.cell.height
          );
        }

        if (rowIndex === body.length - 1) {
          //draw top line for all cells
          // pdf.line(cellX, cellY, cellX + data.cell.width, cellY);

          //draw bottom line for all cells
          pdf.line(
            cellX,
            cellY + data.cell.height,
            cellX + data.cell.width,
            cellY + data.cell.height
          );
        }
      }
    },
  });

  return pdf.lastAutoTable.finalY;
};

const generateDebitNoteReturnFooter = (pdf, posY, debitNote, padding) => {
  const header = [
    { header: "", dataKey: "placeholder" },
    { header: "", dataKey: "divider" },
    { header: "", dataKey: "value" },
  ];

  const body = [
    {
      placeholder: "Jumlah Harga BKP yang dikembalikan\n",
      divider: ":",
      value: priceFormat(debitNote.DNValue),
    },
    {
      placeholder: "Dikurangi Potongan Harga",
      divider: ":",
      value: "0",
    },
    {
      placeholder: "Dasar Pengenaan Pajak",
      divider: ":",
      value: priceFormat(debitNote.DNValue),
    },
    {
      placeholder: "Pajak Pertambahan Nilai yang diminta kembali",
      divider: ":",
      value: priceFormat(debitNote.TaxValue),
    },
    {
      placeholder: "Pajak Penjualan Atas Barang Mewah yang diminta kembali",
      divider: ":",
      value: "",
    },
  ];

  autoTable(pdf, {
    startY: posY,
    columns: header,
    body: body,
    theme: "plain",
    styles: {
      lineWidth: 0,
      fontSize: 10,
      font: "helvetica",
      fontStyle: "normal",
      valign: "middle",
      lineColor: [0, 0, 0], //black
    },
    showHead: "never",
    margin: {
      left: padding,
      right: padding,
      bottom: padding,
    },
    columnStyles: {
      placeholder: { cellWidth: 9.94, cellPadding: 0.14 },
      divider: { cellWidth: 10.312, cellPadding: 0.14 },
      value: { cellPadding: 0.14 },
    },
    didParseCell(data) {
      if (data.section === "head") {
        return;
      }

      const rowIndex = data.row.index;
      const columnIndex = data.column.index;
      const cellX = data.cell.x;
      const cellY = data.cell.y;

      if (columnIndex === header.length - 1) {
        data.cell.styles.halign = "right";
      }

      if (rowIndex === 0) {
        data.cell.styles.valign = "top";
      }

      if (
        rowIndex === body.length - 1 ||
        posY >= pdf.internal.pageSize.height - 3
      ) {
        pdf.setLineWidth(0.07);
        pdf.setDrawColor(0, 0, 0);

        pdf.line(
          cellX,
          cellY + data.cell.height,
          cellX + data.cell.width,
          cellY + data.cell.height
        );
      }
    },
    didDrawCell(data) {
      if (data.section === "head") {
        return;
      }

      const rowIndex = data.row.index;
      const columnIndex = data.column.index;

      const cellX = data.cell.x;
      const cellY = data.cell.y;
      const cellWidth = data.cell.width;
      const cellHeight = data.cell.height;

      pdf.setLineWidth(0.07);
      pdf.setDrawColor(0, 0, 0);

      if (rowIndex === 0) {
        pdf.setLineWidth(0.07);
        pdf.setDrawColor(0, 0, 0);

        //draw top line for all cells
        pdf.line(cellX, cellY, cellX + cellWidth, cellY);

        pdf.line(
          cellX,
          cellY + cellHeight,
          cellX + cellWidth,
          cellY + cellHeight
        );
      }

      if (columnIndex === 0) {
        //draw left line
        pdf.line(cellX, cellY, cellX, cellY + cellHeight);
      } else {
        pdf.line(
          cellX + cellWidth,
          cellY,
          cellX + cellWidth,
          cellY + cellHeight
        );
      }
    },
  });

  return pdf.lastAutoTable.finalY;
};

const generateDebitNoteReturnSign = (
  pdf,
  posY,
  pageWidth,
  pageHeight,
  padding,
  debitNote
) => {
  pdf.setLineWidth(0.07);
  pdf.rect(
    setPos(pageWidth, 0, padding),
    setPos(pageHeight, posY, 0),
    setPos(pageWidth, pageWidth, padding),
    4
  );

  const endY = posY + 4;

  const date = `Jakarta, ${
    debitNote.TaxReturnDate ? formatDate(debitNote.TaxReturnDate, true) : "-"
  }`;
  const dateWidth = pdf.getTextDimensions(date).w;

  const buyer = "Pembeli";
  const buyerWidth = pdf.getTextDimensions(buyer).w;

  const name = "(     Harsinto     )";
  const nameWidth = pdf.getTextDimensions(name).w;

  const director = "Direktur";
  const directorWidth = pdf.getTextDimensions(director).w;

  posY += 0.5;
  const startPos = pageWidth - pageWidth / 3;
  const buyerPos = startPos + (dateWidth - buyerWidth) / 2;
  const namePos = startPos + (dateWidth - nameWidth) / 2;
  const directorPos = startPos + (dateWidth - directorWidth) / 2;

  pdf.text(
    date,
    setPos(pageWidth, pageWidth - pageWidth / 3, padding),
    setPos(pageHeight, posY, 0)
  );

  posY += 0.5;

  pdf.text(
    buyer,
    setPos(pageWidth, buyerPos, padding),
    setPos(pageHeight, posY, 0)
  );

  posY += 2;

  pdf.text(
    name,
    setPos(pageWidth, namePos, padding),
    setPos(pageHeight, posY, 0)
  );

  posY += 0.3;

  pdf.setLineWidth(0.03);
  pdf.line(
    setPos(pageWidth, startPos - 0.5, padding),
    setPos(pageHeight, posY, 0),
    setPos(pageWidth, startPos + dateWidth + 0.5, padding),
    setPos(pageHeight, posY, 0)
  );

  posY += 0.5;

  pdf.text(
    director,
    setPos(pageWidth, directorPos, padding),
    setPos(pageHeight, posY, 0)
  );

  return endY;
};

const generateDebitNoteReturnOtherInfo = (
  pdf,
  posY,
  pageWidth,
  pageHeight,
  padding
) => {
  const innerPadding = 0.3;
  pdf.setLineWidth(0.07);
  pdf.rect(
    setPos(pageWidth, 0, padding),
    setPos(pageHeight, posY, 0),
    setPos(pageWidth, pageWidth, padding),
    1.5
  );

  posY += 0.5;

  pdf.text(
    "Lembar ke-1 untuk Pengusaha Kena Pajak yang menerbitkan Faktur Pajak",
    setPos(pageWidth, innerPadding, padding),
    setPos(pageHeight, posY, 0)
  );

  posY += 0.5;

  pdf.text(
    "Lembar ke-2 untuk Pembeli",
    setPos(pageWidth, innerPadding, padding),
    setPos(pageHeight, posY, 0)
  );

  return posY;
};

/*DN CLAIM FORMAT*/

const generateHeaderInfo = (
  pdf,
  padding,
  marginLeft,
  marginRight,
  cursorY,
  body
) => {
  const header = [
    { header: "", dataKey: "title" },
    { header: "", dataKey: "delimiter" },
    { header: "", dataKey: "value" },
  ];

  const pageWidth = pdf.internal.pageSize.width - padding * 2;

  autoTable(pdf, {
    startY: cursorY,
    columns: header,
    body: body,
    showHead: "never",
    styles: {
      lineWidth: 0,
      fontSize: 12,
      font: "helvetica",
      valign: "top",
    },
    columnStyles: {
      title: { cellWidth: 4.6, cellPadding: 0.1 },
      delimiter: { cellWidth: 0.5, cellPadding: 0.1 },
      value: { cellWidth: pageWidth / 2 - 0.5 - 4.6, cellPadding: 0.1 },
    },
    theme: "plain",
    margin: {
      left: marginLeft,
      right: marginRight,
    },
    didParseCell(data) {
      const columnIndex = data.column.index;
      if (columnIndex === 2) {
        data.cell.styles.halign = "left";
      }
    },
  });

  return pdf.lastAutoTable.finalY;
};

const determineVendorAddress = (vendor) => {
  const addr = vendor.Address.toLowerCase();
  if (
    addr.includes(vendor.City.toLowerCase()) ||
    addr.includes(vendor.District.toLowerCase()) ||
    addr.includes(vendor.SubDistrict.toLowerCase()) ||
    addr.includes(vendor.Province.toLowerCase())
  ) {
    return vendor.Address;
  } else {
    return `${vendor.Address}, ${vendor.SubDistrict}, ${vendor.District}, ${vendor.City}, ${vendor.Province}`;
  }
};

const determineDebitNoteReference = (debitNote) => {
  if (debitNote.DnReferenceCode) {
    return debitNote.DnReferenceCode;
  }

  if (debitNote.Notes) {
    return debitNote.Notes;
  } else {
    return "-";
  }
};

const generateHeaderInfoForClaimFormat = (
  pdf,
  resp,
  cursorY,
  pageWidth,
  pageHeight,
  padding
) => {
  pdf.setFontSize(14);
  pdf.setFont("helvetica", "bold");
  const tittle = "DEBIT NOTE";
  const tittleWidth = pdf.getTextDimensions(tittle).w;
  const paperWidth = pdf.internal.pageSize.width;
  pdf.text(
    "DEBIT NOTE",
    setPos(pageWidth, (paperWidth - tittleWidth) / 2.2, padding),
    setPos(pageHeight, 0, padding)
  );

  pdf.setFont("helvetica", "normal");
  const vendor = resp.supplier;
  const vendorAddr = determineVendorAddress(vendor);
  const company = resp.company;
  const debitNote = resp.debitNote;

  cursorY += 2;
  const headerStart = cursorY;

  let body = [
    { title: "Vendor Name", delimiter: ":", value: vendor.Name },
    { title: "Vendor Address", delimiter: ":", value: vendorAddr },
    { title: "Vendor NPWP", delimiter: ":", value: vendor.Npwp },
    { title: "Purchase Org Name", delimiter: ":", value: company.Name },
    { title: "Purchase Org Address", delimiter: ":", value: company.Address },
    { title: "Purchase Org NPWP", delimiter: ":", value: company.Npwp },
  ];

  cursorY = generateHeaderInfo(
    pdf,
    padding,
    setPos(pageWidth, 0, padding),
    pageWidth / 2,
    cursorY,
    body
  );

  const dnDate = debitNote.SupplierCNDate.includes("0001-01-01")
    ? moment.utc(debitNote.PostingDate).format("DD-MM-YYYY")
    : moment.utc(debitNote.SupplierCNDate).format("DD-MM-YYYY");

  body = [
    { title: "Debit Note No", delimiter: ":", value: debitNote.Code },
    { title: "Debit Note Date", delimiter: ":", value: dnDate },
    {
      title: "Reference",
      delimiter: ":",
      value: determineDebitNoteReference(debitNote),
    },
  ];

  generateHeaderInfo(
    pdf,
    padding,
    pageWidth / 1.8,
    setPos(pageWidth, 0, padding),
    headerStart,
    body
  );

  return cursorY;
};

const generateDnInfoForClaimFormat = (
  pdf,
  resp,
  cursorY,
  pageWidth,
  pageHeight,
  padding
) => {
  const dn = resp.debitNote;
  pdf.lin;
  const header = [
    { header: "No", dataKey: "no" },
    { header: "Description", dataKey: "description" },
    { header: "Amount", dataKey: "amount" },
    { header: "Vat(PPN)", dataKey: "vat" },
    { header: "Total Amount", dataKey: "totalAmount" },
  ];

  const dnAmount = dn.DPP <= 0 ? priceFormat(dn.DNValue) : priceFormat(dn.DPP);

  const body = [
    {
      no: 1,
      description: dn.Notes ? dn.Notes : "-",
      amount: dnAmount,
      vat: priceFormat(dn.TaxValue),
      totalAmount: priceFormat(dn.TotalDnValue),
    },
    {
      no: "",
      description: "",
      amount: "",
      vat: "Total",
      totalAmount: priceFormat(dn.TotalDnValue),
    },
  ];

  autoTable(pdf, {
    startY: cursorY,
    columns: header,
    body: body,
    showHead: "firstPage",
    styles: {
      lineWidth: 0.04,
      lineColor: [0, 0, 0],
      fontSize: 12,
      font: "helvetica",
      valign: "top",
    },
    columnStyles: {
      no: { cellWidth: 1, cellPadding: 0.1 },
      description: { cellWidth: pageWidth / 2 - 0.5 - 3, cellPadding: 0.1 },
      // vat: { cellWidth: 1, cellPadding: 0.1 },
      // unit: { cellWidth: 1, cellPadding: 0.1 },
      // totalAmount: { cellWidth: 2, cellPadding: 0.1 },
    },
    theme: "plain",
    margin: {
      left: padding,
      right: padding,
    },
    didParseCell(data) {
      const columnIndex = data.column.index;
      const rowIndex = data.row.index;
      if (columnIndex <= 1) {
        data.cell.styles.halign = "left";
      } else {
        data.cell.styles.halign = "right";
      }

      // const cellX = data.cell.x;
      // const cellY = data.cell.y;
      // const cellWidth = data.cell.width;
      // const cellHeight = data.cell.height;

      if (rowIndex === body.length - 1 && columnIndex < header.length - 1) {
        data.cell.styles.lineWidth = 0.0;
        data.cell.styles.halign = "left";
      }
    },
  });

  return pdf.lastAutoTable.finalY;
};

const generateDnFooterForClaimFormat = (
  pdf,
  resp,
  cursorY,
  pageWidth,
  pageHeight,
  padding
) => {
  const startPos = cursorY;
  const supplierBank = resp.supplierAccount;
  pdf.text(
    supplierBank && supplierBank.BankName ? supplierBank.BankName : "",
    setPos(pageWidth, 1, padding),
    setPos(pageHeight, cursorY, 0)
  );

  cursorY += 0.8;

  pdf.text(
    supplierBank && supplierBank.AccountNumber
      ? supplierBank.AccountNumber
      : "",
    setPos(pageWidth, 1, padding),
    setPos(pageHeight, cursorY, 0)
  );

  cursorY = startPos;
  const sincerely = "Hormat Kami";
  const sincerelyWidth = pdf.getTextDimensions(sincerely).w;
  const sign = "(.............................)";
  const signWidth = pdf.getTextDimensions(sign).w;
  const signPos = pageWidth / 2 - 2;
  const sincerelyPos = signPos + (signWidth - sincerelyWidth) / 2 - 0.1;
  pdf.text(
    "Hormat Kami",
    setPos(pageWidth, sincerelyPos, padding),
    setPos(pageHeight, cursorY, 0)
  );

  cursorY += 3;

  pdf.text(
    sign,
    setPos(pageWidth, signPos, padding),
    setPos(pageHeight, cursorY, 0)
  );
};

const printDebitNotePDF = async (dn_id, format) => {
  try {
    showLoading();
    const padding = 1.2;
    const height = 37.1856;
    const width = 28.6512;
    const pageWidth = width - padding * 2;
    const pageHeight = height - padding * 2;

    const pdf = new jsPDF({
      unit: "cm",
      format: [width, height],
    });

    pdf.setFontSize(11);
    let cursorY = 0;
    if (format === "dn-claim") {
      const response = await getDebitNote([dn_id]);
      if (response.code > 299) {
        Vue.prototype.$vs.notify({
          title: "Error",
          text: response.message,
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        hideLoading();
        return;
      }

      cursorY = generateHeaderInfoForClaimFormat(
        pdf,
        response.data[0],
        cursorY,
        pageWidth,
        pageHeight,
        padding
      );

      cursorY += 1;

      cursorY = generateDnInfoForClaimFormat(
        pdf,
        response.data[0],
        cursorY,
        pageWidth,
        pageHeight,
        padding
      );

      cursorY += 1;

      cursorY = generateDnFooterForClaimFormat(
        pdf,
        response.data[0],
        cursorY,
        pageWidth,
        pageHeight,
        padding
      );

      const url = pdf.output("blob");
      const urlObject = URL.createObjectURL(url);
      hideLoading();
      return urlObject;
    } else if (format === "dn-return") {
      const response = await getDebitNoteDetail(dn_id);
      if (response.code > 299) {
        Vue.prototype.$vs.notify({
          title: "Error",
          text: response.message,
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        hideLoading();
        return;
      }

      const dnLines = response.data.lines;
      let pr_ids = [];
      let sr_ids = [];
      dnLines.forEach((line) => {
        if (line.SupplierReturnID) {
          sr_ids.push(line.SupplierReturnID);
        } else if (line.PurchaseReturnID) {
          pr_ids.push(line.PurchaseReturnID);
        }
      });

      pr_ids = [...new Set(pr_ids)];
      sr_ids = [...new Set(sr_ids)];
      const params = {
        pr_ids,
        sr_ids,
        is_edit: true,
      };

      const responseDetail = await getSupplierReturnDetails(params);
      if (responseDetail.code > 299) {
        Vue.prototype.$vs.notify({
          title: "Error",
          text: responseDetail.message,
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        hideLoading();
        return;
      }

      const debitNote = response.data.debitNotes;
      const supplier = response.data.supplier;
      const company = response.data.company;
      const taxInvoiceReference = response.data.taxInvoiceReference;
      const lines = response.data.lines;

      cursorY = generateDebitNoteReturnHeader(
        pdf,
        pageWidth,
        pageHeight,
        padding,
        debitNote,
        taxInvoiceReference,
        company,
        supplier
      );

      cursorY = generateDebitNoteReturnLine(
        pdf,
        cursorY,
        pageHeight,
        lines,
        padding
      );

      const currentPage = pdf.internal.getCurrentPageInfo().pageNumber;
      // console.log("current page", currentPage);
      const buffer = currentPage === 1 ? 11.5 : 11.1;
      // console.log("cursorY plus", buffer, cursorY + buffer);
      if (cursorY + buffer >= height - padding) {
        pdf.addPage();
        cursorY = padding;
      }

      cursorY = generateDebitNoteReturnFooter(pdf, cursorY, debitNote, padding);

      cursorY = generateDebitNoteReturnSign(
        pdf,
        cursorY,
        pageWidth,
        pageHeight,
        padding,
        debitNote
      );

      cursorY = generateDebitNoteReturnOtherInfo(
        pdf,
        cursorY,
        pageWidth,
        pageHeight,
        padding
      );

      const pageCount = pdf.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        pdf.setPage(i);
        pdf.setFontSize(10);
        pdf.text(
          `${i}/${pageCount}`,
          setPos(pageWidth, pageWidth - 2, padding),
          pdf.internal.pageSize.height - 1
        );
      }

      const url = pdf.output("blob");
      const urlObject = URL.createObjectURL(url);
      hideLoading();
      return urlObject;
    }
  } catch (e) {
    console.error(e);
  }
};

export default {
  printDebitNotePDF,
};
