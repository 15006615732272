<template>
  <iframe
    v-if="blobUrl"
    :src="blobUrl"
    style="width: 100%; height: 100dvh"
    ref="iframeRef"
    @load="printIframe"
  />
</template>

<script>
import debitNoteHelper from "../../../../helpers/debit-note-helper";
export default {
  async mounted() {
    const param = JSON.parse(atob(this.$route.query.param));
    this.blobUrl = await debitNoteHelper.printDebitNotePDF(
      param.debit_note_id,
      param.format
    );
  },
  data() {
    return {
      blobUrl: "",
    };
  },
  methods: {
    printIframe() {
      if (!this.blobUrl) {
        return;
      }
      this.$refs.iframeRef.contentWindow.print();
    },
  },
};
</script>
